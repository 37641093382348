body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header-part {
  background-color: antiquewhite;
}

.social-icons {
  display: flex;
  gap: 2px;
  display: flex;
  justify-content: center;
}

.social-icons img {
  width: 55px;
  height: 55px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 800px;
}

.social-icons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.image-container {
  position: relative;
  display: inline-block;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.image-container img {
  display: block;
  width: 100%;
  height: auto;
}

.book-button {
  position: absolute;
  bottom: 12px;
  right: 0;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #f56f00;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  width: 140px;
  border-radius: 20px;
  margin-top: 20px;
}

.bottom-social-icons {
  display: none;
}

@media (max-width: 576px) {
  .wrapper {
    min-height: 700px;
  }

  .social-icons {
    display: none;
  }

  .book-button {
    width: 50%;
    font-size: 14px;
    transform: none;
    top: 10%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}








.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
}

.footer-links li {
  margin: 0 20px;
}

.footer-links a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  padding: 10px 20px; 
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #ccc;
}

.footer {
  text-align: center;
  padding-top: 20px; 
  padding-bottom: 10px;
}

.copyright {
  margin-top: 20px; 
  color: #666; 
  font-size: 14px;
}


